import React from 'react';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import Section from '@latitude/section';
import './_labRetailerPartnerList.scss'
import { Typography } from '../Typography';

const RetailerImage = ({ src, name, link }) => (
  <a href={link} target='_blank' rel='noopener noreferrer'>
    <img src={src} alt={name} className='retailer-image'/>
  </a>
);

const LabRetailerPartnerList = ({ data }) => {
  
  const { title, link, logos } = data[0];  
  const linkText = link?.content?.[0]?.content?.[1]?.content?.[0]?.value;
  const linkUrl = link?.content?.[0]?.content?.[1]?.data?.uri;

  return (
    <AnalyticsLocationProvider location={title}>
      <Section className="retailer-container">
        {title && <Typography.H4 className="heading">{title}</Typography.H4>}
        
        <div className="retailer-image-container">
          {logos?.map((logo, index) => {
            const logoUrl = logo?.logo?.file?.url;
            const logoName = logo?.name;
            return (
              <RetailerImage
                key={index}
                src={logoUrl}
                name={logoName}
                link="#"
              />
            );
          })}
        </div>

        {linkUrl && linkText && (
          <div className='retailers-list'>
            <a href={linkUrl} className='retailers-link'>{linkText}</a>
          </div>
        )}
      </Section>
    </AnalyticsLocationProvider>
  );
};

export default LabRetailerPartnerList;