import React, { useContext } from 'react';
import Helmet from 'react-helmet';

import { FeaturesSlider } from '@latitude/features-slider';
import { NotificationBanner } from '@latitude/banner';

import Layout from '../components/layout';
import FeaturesStaggeredSlider from '../components/FeaturesStaggeredSlider/FeaturesStaggeredSlider';
import PageData from '../data/pages/index.json';
import MobileAppInstallPrompts from '@latitude/mobile-app-install-prompt/MobileAppInstallPrompt';
import PromoBanner from '../components/lab-components/PromoBanner';
import HeroBanner from '../components/lab-components/HeroBanner';
import CalculatorLinks from '../components/CalculatorLinks/CalculatorLinks';
import QuickLinks from '../components/QuickLinks/QuickLinks';
import { PageContext } from '@/context/PageContext';
import LabRetailerPartnerList from '@/components/lab-components/LabRetailerPartnersList';
import ReatailerPageData from '@/components/lab-components/LabRetailerPartnersList/LabRetailerPartnerList.mock.json';

const IndexPage = props => {
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || PageData.contentful;
  const featureSliderData = [{
    heading: "Why partner with Latitude?",
    featureCards: PageData.whyChooseLatitude
  }]

  return (
    <Layout location={props.location}>
      <MobileAppInstallPrompts />
      <main className="navigation-spacer">
        <Helmet>
          <link rel="canonical" href="https://www.latitudefinancial.com.au/" hreflang="x-default"/>
          <meta
            name="description"
            content="Simplify your finances with Latitude. Explore credit cards with Interest Free plans, travel credit cards, and personal loans designed to help you achieve your goals"
          />
          <title>Credit Cards & Personal Loans Australia | Apply With Latitude</title>
        </Helmet>
        {!state?.alertData && (
          <NotificationBanner siteName="latitudefinancial-com-au" />
        )}
        
        {state?.heroBannerData?.[0] && (
          <HeroBanner {...state?.heroBannerData[0]} />
        )}

        {state?.promoBannerData?.[0] && (
          <PromoBanner {...state?.promoBannerData[0]} />
        )}

        {state?.promoBannerData?.[1] && (
          <PromoBanner {...state?.promoBannerData[1]} />
        )}
        <FeaturesSlider
          css={`
            h4,h5{
            color: #0050d5;
            }

            svg > path {
            fill: #0050d5;
            }

            svg > g {
            stroke : #0050d5 !important;
            }
            
          `}
          data={PageData.aboutLatitude}
          className="whats-important"
          heading="At Latitude, we’re your Partners in Money, we make it possible with financing solutions in the moments that matter"
          responsiveSettings={PageData.categoriesResponsiveSettings}
        />
        <CalculatorLinks />

        <LabRetailerPartnerList
          data= {
              state?.partnersListData?.length > 0
              ? state?.partnersListData
              : ReatailerPageData
          }
        /> 

        <FeaturesStaggeredSlider
          css={`
            background-color: #f8f8f8;
            .card-icon__image::after {
              background: transparent !important;
            }
            .slick-track .slick-slide:nth-child(2n + 2) {
              -webkit-transform: none !important;
              -ms-transform: none !important;
              transform: none !important;
            }
            .lfs-slide {
              box-shadow: 0 3px 2px 0px rgba(0, 0, 0, 0.25) !important;
            }
            .card-icon h5 {
              font-size: 18px !important;
              margin: 10px 0 !important;
            }
            .card-icon__content p {
              color: #696969 !important;
            }
            .whats-important .slick-slider .slick-slide.slick-active {
              margin: 0 5px !important;
            }
          `}
          data={PageData.categories}
          className="whats-important"
          heading="What’s important to you?"
          responsiveSettings={PageData.categoriesResponsiveSettings}
        />
        <QuickLinks />
        <FeaturesSlider
          key={(state?.featureSliderData?.[0] || featureSliderData[0]).heading}
          heading={
            (state?.featureSliderData?.[0] || featureSliderData[0]).heading
          }
          subheading={
            (state?.featureSliderData?.[0] || featureSliderData[0]).description
          }
          data={
            (state?.featureSliderData?.[0] || featureSliderData[0]).featureCards
          }
          responsiveSettings={PageData.whyChooseLatitudeResponsoveSettings}
        />
      </main>
    </Layout>
  );
};

export default IndexPage;
